.contact-wrapper {
  width: 100%;

  padding-top: 30px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--base-color-yellow);

  p[class='footer'] {
    margin-top: 20px;
    text-align: center;
    color: black;

    a {
      font-size: min(max(var(--font-size-tinny), 1.5vw), var(--font-size-xsmall));
    }
  }

  > img {
    margin-bottom: 30px;
  }

  .contact_box {
    display: grid;
    max-width: 910px;
    grid-template-columns: 1fr 1fr;
    column-gap: 35px;
    row-gap: 40px;
    grid-template-rows: auto auto 80px;
    grid-template-areas:
      'header   header'
      'contacts form'
      'footer   footer';
    margin: 0 5%;
    padding: 70px 70px 35px 70px;
    background: white;
    transition: all 2s;

    @media screen and (max-width: 768px) {
      width: 85%;
      padding: 70px 7% 35px 7%;
      grid-template-areas:
        'header'
        'contacts'
        'form'
        'footer';
      grid-template-rows: auto auto auto auto;
      grid-template-columns: 1fr;
    }
  }

  .contact_box-header {
    grid-area: header;
    h3 {
      text-align: center;
      font-size: var(--font-size-colossal);
      font-weight: 300;
    }
    .black_line {
      margin: 20px auto 0 auto;
      height: 0;
      width: 35px;
      border-top: 3px solid black;
    }
  }
  .contact_box-contacts {
    height: 100%;
    grid-area: contacts;
    margin: 0 auto;
  }
  .contact_box-form {
    width: 100%;
    height: 100%;
    grid-area: form;
    margin: 0 auto;
  }
  .contact_box-footer {
    grid-area: footer;
    margin: 20px auto 0 auto;
    font-size: var(--font-size-medium);
  }
}

$width: 6px;
$size: 14px;
$gap: 5%;

.arrow {
  z-index: 5;
  position: absolute;
  border: solid white;
  border-width: 0 $width $width 0;
  border-radius: 7px;
  display: inline-block;
  padding: $size;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  opacity: 0.5;
  cursor: pointer;
  transition: 500ms;

  &:hover {
    opacity: 1;
  }
}
.right {
  right: $gap;
  top: 50%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

  &:hover {
    transform: rotate(-45deg) scale(1.1);
    -webkit-transform: rotate(-45deg) scale(1.1);
  }
}

.left {
  left: $gap;
  top: 50%;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);

  &:hover {
    transform: rotate(135deg) scale(1.1);
    -webkit-transform: rotate(135deg) scale(1.1);
  }
}

.center {
  left: 50%;
  top: 50%;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);

  &:hover {
    transform: rotate(135deg) scale(1.1);
    -webkit-transform: rotate(135deg) scale(1.1);
  }
}

.modal-left {
  top: calc(50% - 50px);
  left: 10%;
}

.modal-right {
  top: calc(50% - 50px);
  right: 10%;
}

.header_infos-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  span,
  a {
    color: var(--base-color-white);
    font-family: var(--font-family-headerInfos);
    font-weight: var(--font-weight-headerInfos);
  }

  .header_infos-phone {
    font-size: min(max(var(--font-size-xlarge), 3vw), var(--font-size-xhuge));
    margin-right: 10px;

    .ddd {
      font-size: min(max(var(--font-size-small), 2vw), var(--font-size-large));
      margin-right: 5px;
    }
  }
  .header_infos-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header_infos-email {
    font-size: min(max(var(--font-size-small), 2vw), var(--font-size-large));
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 768px) {
  .header_infos-wrapper {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    .header_infos-phone {
      margin-right: 0;
      margin-bottom: 2px;
    }

    .header_infos-box {
      justify-content: flex-start;
      padding-top: 2px;
    }
    .header_infos-email {
      margin-bottom: 0;
    }

    .header_infos-info {
      margin-top: 0;
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  color: inherit;
  font-family: var(--font-family-text);
  font-weight: var(--font-weight-text);
  text-decoration: none;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  font-size: 10px;
}

body {
  height: 100%;
}

#root {
  overflow-x: hidden;
  width: 100%;
}

ol,
ul,
li {
  list-style: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

strong {
  font-weight: 400;
}

i {
  font-style: italic;
}

u {
  text-decoration: underline;
}

.products-wrapper {
  width: 100%;
  background-image: url('../../../media/images/backgrounds/bg_01.png');
  background-attachment: fixed;
  background-repeat: repeat;
  background-size: auto;
}

.products-card-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 30px;
}

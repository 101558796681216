.slogan-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 85%;
  margin-bottom: 25px;
  padding-top: 35px;

  font-size: min(max(var(--font-size-medium), 1.7vw), var(--font-size-large));
  line-height: min(max(23px, 1.9vw), 26px);

  h1 {
    width: 100%;
    text-align: left;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  p {
    margin: 10px 0 10px 0;
    text-align: justify;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  b {
    font-weight: 400;
  }

  h2 {
    margin: 10px 0 10px 0;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  span {
    margin-bottom: 10px;
  }
}

.gallery-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgb(223, 223, 223);
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  padding: 20px;
  margin: 0;
  cursor: pointer;

  .fade_transition {
    display: block;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hover_box {
    display: block;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0);

    figcaption {
      display: block;
      z-index: 3;
      color: rgba(0, 0, 0, 0);
      font-size: var(--font-size-small);
    }

    &:hover {
      transition: all 300ms;
      background-color: rgba(255, 255, 255, 0.5);

      figcaption {
        transition: all 500ms;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}

@font-face {
    font-family: 'DINNeuzeitGrotesk LT';
    src: url('DINNeuzeitGroteskLT-Light.eot');
    src: local('DIN 30640 Neuzeit Grotesk LT Light'), local('DINNeuzeitGroteskLT-Light'),
        url('DINNeuzeitGroteskLT-Light.eot?#iefix') format('embedded-opentype'),
        url('DINNeuzeitGroteskLT-Light.woff2') format('woff2'),
        url('DINNeuzeitGroteskLT-Light.woff') format('woff'),
        url('DINNeuzeitGroteskLT-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINNeuzeitGrotesk LT BoldCond';
    src: url('DINNeuzeitGroteskLT-BoldCond.eot');
    src: local('DIN 30640 Neuzeit Grotesk LT Bold Condensed'), local('DINNeuzeitGroteskLT-BoldCond'),
        url('DINNeuzeitGroteskLT-BoldCond.eot?#iefix') format('embedded-opentype'),
        url('DINNeuzeitGroteskLT-BoldCond.woff2') format('woff2'),
        url('DINNeuzeitGroteskLT-BoldCond.woff') format('woff'),
        url('DINNeuzeitGroteskLT-BoldCond.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


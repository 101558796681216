.gallery-mosaic-wrapper {
  position: relative;
  width: 100%;
}

.gallery-mosaic {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  width: 100%;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));

    :nth-last-child(-n + 3) {
      display: none;
    }
  }
}

@media screen and (min-width: 600px) {
  .card-tall-toDown {
    grid-row: span 2 / auto;
  }

  .card-wide-toRight {
    grid-column: span 2 / auto;
  }
}

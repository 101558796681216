.menu {
  display: flex;
  font-size: min(max(var(--font-size-small), 1.8vw), var(--font-size-medium));
  .menu-item {
    padding: 0 1vw 0 1vw;
    color: var(--base-color-white);
    cursor: pointer;
    transition: color 300ms;

    &:hover {
      color: var(--base-color-gray);
    }
  }
}

@media screen and (max-width: 768px) {
  .menu {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 75px;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 30px;
    background-color: rgba(60, 60, 60, 0.96);

    &-visible {
      opacity: 1;
      pointer-events: all;
    }

    &-transition {
      transition: 300ms;
    }

    .menu-item {
      width: 70%;
      padding: 20px 0 20px 0;
      font-size: var(--font-size-large);
      text-align: center;
      border-bottom: 1px solid white;

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
}

.product_card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 210px;
  min-width: 200px;
  margin: 15px;
  flex-grow: 1;

  .product_card-image {
    width: 122px;
    height: 122px;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 3px solid white;
    background-size: cover;
    background-position: center;
    transition: all 0.5s;

    &:hover {
      border: 1px solid white;
      transform: scale(1.05);
    }
  }

  .product_card-content {
    h2 {
      font-size: var(--font-size-large);
      line-height: 25px;
      font-weight: 400;
      color: white;
      text-align: center;
      margin-bottom: 10px;
    }
    p {
      font-family: var(--font-family-productCards);
      font-weight: var(--font-weight-productCards);
      font-size: var(--font-size-medium);
      line-height: 22px;
      color: white;
      text-align: center;
    }
  }
}

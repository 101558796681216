///////////////////
.label-focus {
  line-height: 20px;
  font-size: 14px;
  top: -7px;
  background: #fff;
  padding: 0 6px;
  left: 9px;
}
///////////////////
.contact_form-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
  transition: 500ms;
  transition-delay: 500ms;
}

.contact_form-wrapper {
  .message-wrapper {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-medium);
    opacity: 0;
    transition: 0;

    .waiting {
      p {
        margin-bottom: 20px;
        text-align: center;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .success {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-bottom: 20px;
      }
      p {
        color: green;
        margin-bottom: 20px;
        text-align: center;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .error {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-bottom: 20px;
      }
      p {
        color: red;
        margin-bottom: 20px;
        text-align: center;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .spinner-wrapper {
    z-index: 1;
    position: relative;
    display: none;
    margin-bottom: 20px;
  }
}

.contact_form-wrapper {
  .contact_form {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 500ms;

    .field {
      position: relative;
      width: 100%;
      margin-top: 13px;

      &:first-of-type {
        margin: 0;
      }

      label::before {
        content: attr(title);
        position: absolute;
        top: 0;
        left: 10px;
        line-height: calc(14px + 16px + 10px + 9px);
        font-size: var(--font-size-small);
        color: #999;
        transition: all 300ms;
      }
      input {
        outline: none;
        width: 100%;
        height: 45px;
        padding: 14px 10px 10px 10px;
        font-size: var(--font-size-small);
        border: 1px solid black;
        transition: all 0.3s;

        &:focus {
          border: 1px solid var(--base-color-yellow);
          border-radius: 1px;
        }

        &:valid + label::before {
          content: attr(data-title);
        }

        &[class='focus'] + label::before {
          @extend .label-focus;
        }
      }

      textarea {
        outline: none;
        width: 100%;
        height: 100px;
        padding: 15px 10px;
        font-size: var(--font-size-small);
        border: 1px solid black;
        transition: all 0.3s;

        &:focus {
          border: 1px solid var(--base-color-yellow);
          border-radius: 1px;
        }
        &[class='focus'] {
          height: 150px;
        }

        &:valid + label::before {
          content: attr(data-title);
        }
        &[class='focus'] + label::before {
          @extend .label-focus;
        }
      }
    }

    button {
      width: 100%;
      height: 35px;
      line-height: 35px;
      margin-top: 10px;
      background-color: var(--base-color-black);

      font-size: var(--font-size-small);
      color: white;

      cursor: pointer;
      transition: 0.25s;

      &:hover {
        background-color: var(--base-color-gray);
      }

      &[disabled] {
        background-color: gray;
      }
    }
  }
}

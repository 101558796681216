.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;
  background: var(--base-color-black);

  span {
    color: white;
    font-size: min(max(var(--font-size-tinny), 1.5vw), var(--font-size-xsmall));
    text-align: center;
  }
}

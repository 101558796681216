$headerHeight: 75px;

header {
  position: fixed;
  top: 0;
  z-index: 10;

  width: 100%;
  height: $headerHeight;
  background-color: var(--base-color-black);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;

    .header_infos {
      display: flex;
      align-items: center;
    }

    .header_menu {
      display: flex;
      align-items: flex-end;
      height: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  @media screen and (max-width: 768px) {
    height: 95px;

    .container {
      padding: 0 15px;
      .header_infos {
        display: flex;
        align-items: center;
      }

      .header_menu {
        align-items: center;
        height: 100%;
      }
    }
  }
}

.logo-wrapper {
  display: flex;
  width: 100%;
  height: 330px;
  align-items: center;
  justify-content: center;
  background-color: var(--base-color-yellow);

  .logo-image {
    margin-top: 75px;
    height: 10vw;
    min-height: 60px;
    max-height: 100px;
  }
}

.contacts_infos {
  font-size: var(--font-size-small);

  address {
    line-height: 22px;
  }

  a {
    line-height: 30px;
  }

  p {
    margin-top: 30px;
    line-height: 20px;
  }

  div[id='opening_hours'] {
    margin-top: 50px;
    & p {
      margin-top: 10px;
    }
  }
}

.gallery-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  p {
    font-size: min(max(var(--font-size-xlarge), 3.5vw), var(--font-size-xhuge));
    font-weight: 400;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 1s;
  pointer-events: all;

  .close_buttom-wrapper {
    z-index: 5;
    position: absolute;
    top: 2%;
    right: 2%;
  }

  figcaption {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: var(--font-size-small);
    color: white;
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .image {
      display: flex;
      justify-content: center;
      img {
        object-fit: contain;
        max-width: 90vw;
        max-height: 80vh;
        transition: all 2s;
      }
    }
  }
}

.modalShow {
  z-index: 20;
  opacity: 1;
  img {
    opacity: 1;
  }
}

.modalHide {
  z-index: -20;
  opacity: 0;
  img {
    opacity: 0;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@import '../settings/fonts/DIN_Neuzeit_Grotesk/stylesheet.css';

@import '../settings/fonts/DIN_Next_LT_Pro/stylesheet.css';

:root {
  --font-size-tinny: 1.2rem;
  --font-size-xsmall: 1.6rem;
  --font-size-small: 1.8rem;
  --font-size-medium: 2rem;
  --font-size-large: 2.4rem;
  --font-size-xlarge: 2.6rem;
  --font-size-huge: 2.8rem;
  --font-size-xhuge: 3rem;
  --font-size-colossal: 3.4rem;

  --font-family-text: 'DIN Next Rounded LT Pro';
  --font-weight-text: 200;

  --font-family-productCards: 'DIN Next LT Pro';
  --font-weight-productCards: 200;

  --font-family-headerInfos: 'DINNeuzeitGrotesk LT BoldCond';
  --font-weight-headerInfos: bold;
}

.progress_bar-wrapper {
  z-index: 4;
  position: absolute;
  height: 3px;
  width: 100%;
  opacity: 1;
  .progress_bar {
    height: 100%;
    width: 0;
    background-color: var(--base-color-yellow);
  }
}

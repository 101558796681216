$size: 60px;
$outsideBorder: 1px solid rgb(210, 210, 210);
$barHeight: 2px;

.burger-wrapper {
  z-index: 50;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: $outsideBorder;
  height: $size;
  width: $size;
}

.burger {
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
  width: 100%;
  cursor: pointer;

  .bar {
    position: absolute;
    height: $barHeight;
    width: 50%;
    background-color: white;
    transition: 300ms ease-in-out;
    border-radius: 10px;

    &:first-of-type {
      top: 25%;
    }
    &:not(:first-of-type):not(:last-of-type) {
      top: calc(50% - $barHeight / 2);
    }
    &:last-of-type {
      bottom: 25%;
    }
  }

  .burger-opened {
    &:first-of-type {
      top: calc(50% - $barHeight / 2);
      transform: rotate(45deg);
    }
    &:not(:first-of-type):not(:last-of-type) {
      transform: scale(0);
    }
    &:last-of-type {
      bottom: calc(50% - $barHeight / 2);
      transform: rotate(-45deg);
    }
  }
}

@media screen and (min-width: 768px) {
  .burger-wrapper {
    display: none;
  }
}
